import React from 'react';
import PropTypes from 'prop-types';
import { destinations } from '../../constants';
import styles from './topic-promos.module.scss';
import { ChevronRight } from '../Svgs/ChevronRight';
import { TrendingIcon } from '../Svgs/TrendingIcon';
import { useUnitTracking } from '../../hooks/analytics/useUnitTracking';

export const TopicPromo = ({
  buzz,
  itemType = 'card',
  positionInUnit = 0,
  subunitName,
  subunitType,
  unitType = 'buzz_body'
}) => {
  const topic = buzz.topicPagePromo;
  const unitEl = useUnitTracking(
    {
      item_type: itemType,
      item_name: topic?.slug,
      subunit_name: subunitName,
      subunit_type: subunitType,
      unit_type: unitType,
      unit_name: buzz.id,
      target_content_type: 'feed',
      target_content_id: 8,
      position_in_unit: positionInUnit,
      target_content_url: buzz?.isNews ? buzz?.canonical_url : null
    }, // unitData
    false, // setEventLabel
    buzz?.isNews // external
  );

  if (!topic) {
    return null;
  }

  const promos = topic.metadata?.promos;
  const images = topic.metadata?.images;

  const url = `${destinations['buzzfeed']?.base_url}/topic/${topic?.slug}`;

  if (itemType === 'text') {
    const title = promos?.tertiary?.title || 'Full coverage and conversation on';
    return (
      <div ref={unitEl} className={styles.textPromo}>
        <div className={styles.hotTopic}>Hot Topic</div>
        <div className={styles.textPromoTitle}>🔥 {title} <a href={url} className={styles.topicLink}>{topic.display_name}<ChevronRight className={styles.carret} fill="#5246F5" /></a></div>
      </div>
    )
  }

  if (subunitName === 'comments') {
    const title = promos?.secondary?.title || 'We see you lurking 👀';
    const description = promos?.secondary?.description || '';
    const wideImage = images?.wide;
    const narrowImage = images?.narrow;

    return (
      <a ref={unitEl} href={url} className={`${styles.commentsPromo} ${!(wideImage && narrowImage) ? styles.noImage : ''}`}>
        {(wideImage && narrowImage) && <picture>
          <source
            srcSet={wideImage}
            media='(min-width: 640px)'
          />
          <img src={narrowImage} aria-hidden />
        </picture>}
        <div className={styles.commentsPromoContent}>
          <div className={styles.commentsPromoCopy}>
            <div className={styles.commentsPromoTitle}>{title}</div>
            <div className={styles.commentsPromoDescription}>{description}</div>
          </div>
          <div className={styles.commentsPromoCta}>
            See the Discussions<ChevronRight className={styles.carret} fill="#5246F5" />
          </div>
        </div>
      </a>
    );
  }

  // visual card promo
  const visualPromoTitle = promos?.primary?.title || '';
  const visualPromoCTA = promos?.primary?.cta || 'Join a Discussion';
  const visualPromoImage = images?.wide;

  const theme =
    topic?.metadata?.theme &&
    topic?.metadata?.theme.color1 &&
    topic?.metadata?.theme.color2 &&
    topic?.metadata?.theme.textColor ? topic.metadata.theme : {
      color1: '#8DAAF8',
      color2: '#C6D4FB',
      textColor: '#222'
    };

  return (
    <a
      ref={unitEl}
      href={url}
      className={
        `${styles.visualPromo} ${!visualPromoImage ? styles.noImage : ''}
        ${visualPromoTitle.length > 90 && visualPromoImage ? styles.longVisualPromoTitle : ''}`
      }
      style={{ '--promoThemeColor1': theme.color1, '--promoThemeColor2': theme.color2, '--promoThemeTextColor': theme.textColor }}
    >
      {visualPromoImage && <img className={styles.visualPromoImage} src={visualPromoImage} aria-hidden />}
      <div className={styles.visualPromoContent}>
        <div className={styles.visualPromoCopy}>
          <div className={styles.visualPromoPill}><TrendingIcon className={styles.visualPromoIcon} aria-hidden />Hot Topic</div>
          <div className={styles.visualPromoTitle}>{visualPromoTitle}</div>
        </div>
        <div className={styles.visualPromoCta}>{visualPromoCTA}
          <ChevronRight className={styles.carret} stroke={theme.textColor}/>
        </div>
      </div>
    </a>
  );
};

TopicPromo.propTypes = {
  buzz: PropTypes.object.isRequired,
  itemType: PropTypes.oneOf(['text', 'card']),
  positionInUnit: PropTypes.number,
  subunitName: PropTypes.string,
  subunitType: PropTypes.string,
};

export default TopicPromo;
