export const ACCOUNT_CID = '1bf35e8b-3d55-48a6-aef8-0ebfdd277df7';

// ELEMENTS
export const CONNATIX_ELEMENTS_DEFAULT = {
  playerId: '74042173-8073-44df-ae60-8d7f3a9c101d',
  embedId: '1ac16932c9b44fbc92c180bf73db2183'
};

export const CONNATIX_ELEMENTS_AU = {
  playerId: 'b24b5c64-2f42-400a-80bf-7708762dc97a',
  embedId: 'fc6bc0cda64c49af9e587ef950704b74',
};

export const CONNATIX_ELEMENTS_JP = {
  playerId: '189e60d0-1e16-4d52-8821-ad6719429b2f',
  embedId: '8b19976e6f4b49d5aa0117c551f165fb',
};

export const CONNATIX_ELEMENTS_STICKY_BFN = {
  playerId: '449abc4a-7db1-4e1e-a9df-11bcceab3a77',
  embedId: 'd32a7c62199744beb3cb1a4c3999dead',
}

export const CONNATIX_ELEMENTS_DEFAULT_BFN = {
  playerId: '4b585a7a-a2da-448d-af46-69f58c1d9b84',
  embedId: '6001585a20a54d85b9f758983faeb75a',
}

export const CONNATIX_ELEMENTS_STICKY_BFDC = {
  playerId: '23bdb39b-81e1-4a9b-84e8-f6e050016b8d',
  embedId: 'd32a7c62199744beb3cb1a4c3999dead',
}

// PLAYSPACE
export const BFN_PLAYSPACE_DEFAULT = {
  trackerId: '4fc1040197bd4cbd9b0d962490cd5696',
  playerId: '1b83f8dc-aadc-4b59-86aa-13a550406007',
};

export const BFN_PLAYSPACE_HIGHER_STICKY = {
  trackerId: '8bc6b6a443d84edfb379353e00defb65',
  playerId: 'eaa36b10-dec7-47a9-9e5a-ea782b7d4413',
};
