import React, { useEffect, useState, useContext, useRef } from "react";
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import {
  ACCOUNT_CID as CID,
  CONNATIX_ELEMENTS_DEFAULT,
  CONNATIX_ELEMENTS_AU,
  CONNATIX_ELEMENTS_JP,
  CONNATIX_ELEMENTS_STICKY_BFN,
  CONNATIX_ELEMENTS_DEFAULT_BFN,
  CONNATIX_ELEMENTS_STICKY_BFDC,
} from '../constants';
import CommentsContext from '../../../contexts/comments';
import { isNews } from '../../../utils/isNews';
import { isElementsEligible, isSickyVideoEligible } from '../../../utils/conatixVideo';
import { useFloatingMode } from '../useFloatingMode';
import styles from './elements.module.scss';

export default function ElementsPlayer({ buzz = {}, showOnBFN = false }) {
  const [cnxPixel, setCnxPixel] = useState(null);
  const adsContext = useContext(AdsContext);
  const [playerApi, setPlayerApi] = useState(null);
  const containerId = useRef(null);
  const hasInitialized = useRef(false);
  const showElements = isElementsEligible(buzz) || showOnBFN;
  const isSticky = isSickyVideoEligible(buzz);
  const { isCommentsPanelOpen } = useContext(CommentsContext);

  // Connatix script is loaded from the head
  // change connatix player based on user country
  // specifically for AU/NZ, and Japan
  let cnxIds = isSticky ? (
    isNews(buzz) ? CONNATIX_ELEMENTS_STICKY_BFN : CONNATIX_ELEMENTS_STICKY_BFDC
  ) : CONNATIX_ELEMENTS_DEFAULT;

  let userCountry = adsContext?.env?.userCountry;
  if (showOnBFN) {
    cnxIds = CONNATIX_ELEMENTS_DEFAULT_BFN;
  } else if(userCountry === 'au' || userCountry === 'nz') {
    cnxIds = CONNATIX_ELEMENTS_AU;
  } else if (userCountry === 'jp') {
    cnxIds = CONNATIX_ELEMENTS_JP;
  }
  let scriptId = `player-${cnxIds.embedId}`;

  useEffect(() => {
    if (!showElements || !userCountry || hasInitialized.current) {
      return;
    }
    hasInitialized.current = true;

    const { playerId } = cnxIds;
    if(!cnxPixel) {
      const pixelUrl = `https://capi.connatix.com/tr/si?token=${playerId}&cid=${CID}`;
      const pixelImg = new Image();
      pixelImg.src = pixelUrl;
      setCnxPixel(pixelImg);
    }

    window.cnx.cmd.push(() => {
      const player = window.cnx({
        playerId,
        settings: {
          rendering: {
            insertPosition: window.cnx.configEnums.InsertPositionEnum.WithinContainer,
          }
        }
      });
      player.render(scriptId, (renderError, api) => {
        if (renderError) {
          console.error('Player rendering failed:', renderError);
          return;
        }
        setPlayerApi(api);
      });
    });
  }, [showElements, userCountry, cnxIds, scriptId, cnxPixel]);

  const shouldEnableFloating = (
    playerApi &&
    window.innerWidth <= 1025 &&
    isSickyVideoEligible(buzz) &&
    !isCommentsPanelOpen
  ) || false;
  useFloatingMode(playerApi, containerId, shouldEnableFloating);

  return (
    <div className={`bf-cnx-override ${styles.bfElementsWrapper}`}>
      <div id={scriptId} ref={containerId} className="bf-cnx-player"></div>
    </div>
  );
}
