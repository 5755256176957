import React, { useContext, useEffect, useState } from 'react';
import BuzzContext from '../../contexts/buzz';
import {
  trackClientExternalLink,
  trackClientImpression,
  trackClientContentAction,
} from '../../hooks/analytics/client-event-tracking';
import { getReferrer } from '@buzzfeed/bf-utils/lib/referrer';

import { AppPromoButton, AppPromoCard } from './components';
import { PromoData, StoreLinks } from './promoConfig'

import styles from './mobileAppPromo.module.scss'


// don't ever show app promos unless this is true
const GlobalBuzzCheck = buzz => (
  !buzz.isShopping &&
  buzz.language == 'en' &&
  buzz.destination_name === 'buzzfeed' &&
  ['Moderated Community', 'Editorial'].includes(buzz.editorial_status)
);

// when the user closes the promo we'll save the date in localStorage
//   and hide the promo for this many days
const HIDE_DAYS = 7;
const STORAGE_KEY = 'MobileAppPromo_closed_at';


function MobileAppPromo() {
  const buzz = useContext(BuzzContext);
  const [device, setDevice] = useState(undefined);
  const [isVisible, setIsVisible] = useState(undefined);
  const [promoContent, setPromoContent] = useState(undefined)

  // spoof branch apis that are used by quizzes
  // eventually we can rework this but it will require changes to quiz bfp code
  const branchPolyfill = {
    addListener: () => { return true },
    track: () => { return true },
    logEvent: () => { return true },
    closeJourney: () => {
      const promos = PromoData.filter(p => p.name === 'quiz-complete');
      const promoContent = promos ? promos[0].content : {};
      setPromoContent(promoContent);
      return true;
    },
  }

  // CET params used for all tracking calls
  const trackingParams = {
    unit_type: 'modal',
    subunit_type: 'component',
    subunit_name: 'mobile_app_promo',
    position_in_unit: 0,
    position_in_subunit: 0,
    item_type: 'button',
    item_name: 'cta',
  }

  // get browser info and hydrate state
  useEffect(() => {
    try {
      if (device === undefined) {
        window.branch = branchPolyfill;
        const userAgent = window.navigator.userAgent;
        const _device =
          /iP(hone|ad|od)/i.test(userAgent) ? 'ios' :
          /Android/i.test(userAgent) ? 'android' :
          'desktop';
        setDevice(_device);
      }

      if (promoContent === undefined) {
        const referrer = getReferrer();
        const promo = PromoData.find(promo => promo.match(buzz, referrer));
        if (promo?.enabled) {
          setPromoContent(promo.content);
        } else {
          setPromoContent(false);
        }
      }

      if (isVisible === undefined) {
        const closedDate = localStorage.getItem(STORAGE_KEY);
        const now = new Date().getTime();
        const hideMilliseconds = HIDE_DAYS * (24 * 60 * 60 * 1000);
        const isClosed = closedDate ? now - closedDate < hideMilliseconds : false;
        setIsVisible(!isClosed);
      }
    }
    catch (err) {
      console.error(err);
      return err;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // fire an impression when the promo updates (including first view)
  useEffect(() => {
    if (promoContent && promoContent?.promoType && isVisible) {
      const storeLink = StoreLinks[device] + promoContent.campaignId;
      // https://github.com/buzzfeed/mono/blob/master/data_registry_api/datatypes/client_tracking_events/impressions/web_impressions_bf.yml
      trackClientImpression(buzz, {
        target_content_id: storeLink,
        target_content_type: 'url',
        unit_name: promoContent.campaignId,
        ...trackingParams,
      });
    }
  }, [promoContent]);

  const isValidBuzz = GlobalBuzzCheck(buzz);
  if (!isValidBuzz || !isVisible || !promoContent || device === 'desktop') {
    return null;
  }

  const handleClick = () => {
    const storeLink = StoreLinks[device] + promoContent.campaignId;
    // https://github.com/buzzfeed/mono/blob/master/data_registry_api/datatypes/client_tracking_events/external_links/web_external_links_bf.yml
    trackClientExternalLink(buzz, {
      target_content_url: storeLink,
      unit_name: promoContent.campaignId,
      ...trackingParams,
    });
    window.location = storeLink;
  };

  const handleClose = () => {
    // https://github.com/buzzfeed/mono/blob/master/data_registry_api/datatypes/client_tracking_events/content_actions/web_content_actions_bf.yml
    trackClientContentAction(buzz, {
      action_type: 'close',
      action_value: 'close_promo',
      unit_name: promoContent.campaignId,
      ...trackingParams,
    });
    let now = new Date().getTime();
    localStorage.setItem(STORAGE_KEY, now);
    setIsVisible(false);
  }

  const PromoComponent = (props) => {
    switch (props.content.promoType) {
      case 'button':
        return <AppPromoButton {...props} />
      case 'card':
        return <AppPromoCard {...props} />
      default:
        return null;
    }
  }

  return (
    <section className={styles.mobileAppPromo}>
      <PromoComponent content={promoContent} onClick={handleClick} onClose={handleClose} />
    </section>
  );
}

export default MobileAppPromo;
