import { useEffect, useRef, useContext } from 'react';
import {
  isAmazon,
} from '@buzzfeed/bf-utils/lib/affiliate-link';
import { rAmazonReviews } from '@buzzfeed/react-components/lib/utils/affiliate';
import ABeagleContext from '../../contexts/abeagle';

/**
 * Added Button’s PostTap prefix to link href
 */
const modifyHREF = ({ element }) => {
  if (!element) {
    return;
  }

  const asinMatch = element.href.match("/([a-zA-Z0-9]{10})(?:[/?]|$)");
  const originAmazonUrl = new URL(element.href);

  element.setAttribute('data-aps-asin', asinMatch && !rAmazonReviews(element.href) ? asinMatch[1] : null);
  element.setAttribute('data-aps-asc-tag', originAmazonUrl.searchParams.get('tag'));
  element.setAttribute('data-aps-asc-subtag', originAmazonUrl.searchParams.get('ascsubtag'));
};

/**
 * hrefModifier should be a function that runs at the end of the async modifyHREF function.
 */
export function useAIGCWrapper({ buzz = {}, index }) {
  const ref = useRef(null);
  const { experiments } = useContext(ABeagleContext);

  useEffect(() => {
    const element = ref.current;

    if (!element || !experiments.loaded) {
      return;
    }

    element.querySelectorAll('a[href]').forEach(link => {
      if (!isAmazon(link.href)) {
        return;
      }

      // only modify ButtonPostTaplinks once
      if (link.classList.contains('js-aagc-modified')) {
        return;
      }
      link.classList.add('js-aagc-modified');
      modifyHREF({ element: link, buzz, index });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiments]);

  return ref;
}
